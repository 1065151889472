import { formatDate } from "../js/Common";
import { strings } from "./Language";
import Popup from "./Popup";

export default function WorkshopSchedule({ opened, setOpened, schedules }) {
  return (
    <Popup id='workshopSchedule' opened={opened} setOpened={setOpened}>
      <h1 className='blue centered'>{strings('workshop.agenda')}</h1>
      {schedules?.map((schedule, index) =>
        <div key={index}>
          {schedule.start || schedule.end ?
            <h2 className='noMargBottom darkBlue'>
              {schedule.start ? formatDate(schedule.start, 'HH:mm') : ''}
              {schedule.start && schedule.end ? ' - ' : ''}
              {schedule.end ? formatDate(schedule.end, 'HH:mm') : ''}
            </h2>
            :
            null}
          <h3 className={schedule.start || schedule.end ? 'noMargTop darkBlue' : 'darkBlue'} dangerouslySetInnerHTML={{ __html: schedule.title }} />
          <div className='lineHeight1_4' dangerouslySetInnerHTML={{ __html: schedule.description }} />
        </div>
      )}

      <div className="centered">
        <span className="button cPointer" onClick={() => setOpened(false)}>{strings('common.close')}</span>
      </div>
    </Popup>
  );
}

