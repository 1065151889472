import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { strings } from '../components/Language';
import { request } from '../js/APIUtils';
import { FORGOT_PASSWORD } from '../js/endpoints';
import { isValidEmail } from '../js/validator';
import Loading from '../components/Loading';

export default function ForgotPassword() {
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [sent, setSent] = useState(false)
  const [submitting, setSubmitting] = useState(false);

  function changeUsername(newValue) {
    setUsername(newValue);
    setUsernameError(false);
  }

  function checkData(event) {
    event.preventDefault();
    const validUsername = isValidEmail(username);

    setUsernameError(!validUsername);

    if (validUsername) {
      setSubmitting(true);
      const body = {
        email: username
      }
      request(FORGOT_PASSWORD, body).then(jsonObject => {
        setSent(true);
        setSubmitting(false);
      })
    }
    return false
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/login.css" />
        </Helmet>
        <Loading isLoading={submitting} />

        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form">
                <span className="login100-form-title">
                  {strings('login.forgotPasswordTitle')}
                </span>
                {sent ?
                  <div className="alert-success">
                    <span>
                      <i className="icon-tick" /> {strings('login.passwordSent')}
                    </span>
                  </div>
                  :
                  [<div key={0} className={'wrap-input100 last' + (usernameError ? ' alert-validate' : '')} data-validate={strings('common.email') + strings('login.isRequired')}>
                    <span className="label-input100">{strings('common.email')}</span>
                    <input className={'input100' + (username !== '' ? ' has-val' : '')} type="text" placeholder={strings('login.typeYour') + strings('common.email').toLowerCase()}
                      onChange={event => changeUsername(event.target.value)} />
                    <span className="focus-input100 email"></span>
                  </div>,
                  <div key={1} className="textRight forgotPw" />,
                  <div key={2} className="container-login100-form-btn">
                    <div className="wrap-login100-form-btn">
                      <div className="login100-form-bgbtn"></div>
                      <button className={'login100-form-btn' + (submitting ? ' disabled' : '')} onClick={(event) => checkData(event)}>
                        {strings('common.send')}
                      </button>
                    </div>
                  </div>]
                }
                <p className="centered">
                  <a href="/login">{strings('login.goBack')}</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}