import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../../css/multiselect.css';
import { strings } from '../Language';

function Multiselect({ id, options, value, onChange, className, multiple = false, label, showAll = false, error }) {
  const containerRef = useRef();
  const [originalValue, setOriginalValue] = useState(multiple ? value != null && Array.isArray(value) && value.length > 0 && value[0] != null ? value : [] : value);
  const [newValue, setNewValue] = useState(originalValue);
  const [open, setOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [maxHeight, setMaxHeight] = useState();
  const [defaultMaxHeight, setDefaultMaxHeight] = useState();

  const closeSelect = useCallback((event) => {
    if (event != null) {
      event.stopPropagation();
    }
    setOpen(false);
    setShowOverlay(false);
  }, [setOpen, setShowOverlay]);

  const mounted = useRef();
  useEffect(() => {
    function closeOnClickOutside(event) {
      if (event.target.closest('#' + id) == null) {
        closeSelect();
      }
    }

    const checkWindowSize = () => {
      if (window.innerWidth <= 619) {
        let anyVisible;
        document.querySelectorAll('.multiSelectContainer').forEach(function (item) {
          anyVisible = anyVisible || item.style.display !== 'none';
        });

        if (anyVisible) {
          document.querySelectorAll('.multiSelectContainer').forEach(function (item) {
            const btn = item.getElementsByClassName('closeBtn')[0];

            document.body.style.overflow = 'hidden';
            setShowOverlay(true);
            const height = parseInt(document.defaultView.getComputedStyle(item).getPropertyValue('height'));
            const btnHeight = parseInt(document.defaultView.getComputedStyle(btn).getPropertyValue('height'));
            const btnMargin = parseInt(document.defaultView.getComputedStyle(btn).getPropertyValue('margin-top')) + parseInt(document.defaultView.getComputedStyle(btn).getPropertyValue('margin-bottom'));
            setMaxHeight(height - (btnHeight + btnMargin));
          })
        } else {
          document.body.style.overflow = 'auto';
          setShowOverlay(false);
          setMaxHeight(defaultMaxHeight);
        }
      } else {
        document.body.style.overflow = 'auto';
        setShowOverlay(false);
        setMaxHeight(defaultMaxHeight);
      }
    }

    const auxValue = multiple ? value != null && Array.isArray(value) && value.length > 0 && value[0] != null ? value : [] : value;
    if (!mounted.current) {
      window.addEventListener('resize', checkWindowSize);
      document.addEventListener('click', closeOnClickOutside);
      for (let item of document.getElementsByTagName('select')) {
        item.addEventListener('mousedown', closeOnClickOutside);
      }

      mounted.current = true;
    } else if (open) {
      checkWindowSize();
    } else if ((multiple && JSON.stringify(auxValue) !== JSON.stringify(newValue)) || (!multiple && auxValue !== newValue)) {
      setNewValue(auxValue);
    }
  }, [setShowOverlay, setMaxHeight, defaultMaxHeight, open, closeSelect, id, value, newValue, multiple]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setDefaultMaxHeight(document.defaultView.getComputedStyle(containerRef.current).getPropertyValue('max-height'));
    }
  }, []);

  function changeValue(selectedValue) {
    let val;
    if (selectedValue == null) {
      val = multiple ? [] : null;
    } else if (multiple) {
      val = [...newValue];
      if (val.indexOf(selectedValue) < 0) {
        val.push(selectedValue);
      } else {
        val.splice(val.indexOf(selectedValue), 1);
      }
    } else {
      val = selectedValue;
    }

    setNewValue(val);
    onChange(val);

    if (!multiple) {
      closeSelect();
    }
  }

  function openSelect() {
    setOriginalValue(newValue);
    setOpen(true);
  }

  function getLabelText(newValue) {
    let labelText;
    if ((newValue == null || newValue.length === 0) && label) {
      labelText = label;
    } else if ((newValue == null || newValue.length === 0) && !label) {
      labelText = strings('common.select');
    } else if (multiple && newValue.length === 1) {
      let item = options.filter(option => option.value === newValue[0])[0];
      labelText = item != null ? item.label : '';
    } else if (!multiple && newValue != null) {
      let item = options.filter(option => option.value === newValue)[0];
      labelText = item != null ? item.label : '';
    } else {
      labelText = newValue.length + ' ' + strings('common.selected');
    }
    return labelText;
  }

  return (
    <div>
      <div className="multiSelectOverlay" style={showOverlay ? { display: 'block' } : null} onClick={closeSelect} />
      <div id={id} className={'multiSelect ' + (className != null ? className : '') + (open ? ' open' : '') + (error ? ' error' : '')} onClick={open ? closeSelect : openSelect}>
        <span>
          {(multiple && newValue.length > 0) || (!multiple && newValue != null) ?
            <label className='multiselectLabel'>{label ? label : strings('common.select')}</label>
            :
            null
          }
          <span className={'selectLabel' + ((multiple && newValue.length > 0) || (!multiple && newValue != null) ? ' selected' : '')}>{getLabelText(newValue)}</span> <i className="icon icon-down-arrow2 multiSelectArrow"></i>
        </span>
        <div className="multiSelectContainer" style={open ? null : { display: 'none' }} onClick={event => event.stopPropagation()}>
          <div id={id + 'Container'} className="multiSelectWrap" style={{ maxHeight: maxHeight }} ref={containerRef}>
            {showAll ?
              <label key={id + '_all'} htmlFor={id + '_all'}>
                <input type="checkbox" name={id} id={id + '_all'} value='' onChange={() => changeValue(null)} style={{ display: 'none' }} />
                <span>{strings('common.all')}</span>
              </label>
              :
              null
            }
            {options.map(option =>
              <label key={id + '_' + option.value} htmlFor={id + '_' + option.value}>
                <input type="checkbox" name={id} id={id + '_' + option.value} value={option.value} defaultChecked={(multiple && value.indexOf(option.value) >= 0) || (!multiple && value === option.value)}
                  onChange={() => changeValue(option.value)} style={multiple ? null : { display: 'none' }} />
                <span>{option.label}</span>
              </label>
            )}
          </div>
          <div>
            <button onClick={closeSelect} className="closeBtn">{strings('common.close')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Multiselect;
