import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { isValidEmail } from '../js/validator';
import { strings } from '../components/Language';
import { USER_KEY, request } from '../js/APIUtils';
import { LOGIN } from '../js/endpoints';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [invalidLoginError, setInvalidLoginError] = useState(false)
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const goToDashboard = () => navigate('/dashboard');

  function changeUsername(newValue) {
    setUsername(newValue);
    setUsernameError(false);
  }

  function changePassword(newValue) {
    setPassword(newValue);
    setPasswordError(false);
  }

  function checkData(event) {
    event.preventDefault();
    const validUsername = isValidEmail(username);
    const validPassword = password.length > 0;

    setInvalidLoginError(false);
    setUsernameError(!validUsername);
    setPasswordError(!validPassword);

    if (validUsername && validPassword) {
      setSubmitting(true);
      request(LOGIN, { email: username, password: password }).then(jsonObject => {
        if (jsonObject.httpStatus !== 200) {
          setInvalidLoginError(true);
          setSubmitting(false);
        } else {
          localStorage.setItem(USER_KEY, jsonObject.id)
          goToDashboard();
        }
      })
    }
    return false
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/login.css" />
        </Helmet>
        <Loading isLoading={submitting} />

        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form">
                <span className="login100-form-title">
                  {strings('login.title')}
                </span>
                <p className="label-invalidLogin red" style={invalidLoginError ? { visibility: 'visible' } : null}>{strings('login.invalidLogin')}</p>
                <div className={'wrap-input100' + (usernameError ? ' alert-validate' : '')} data-validate={strings('common.email') + strings('login.isRequired')}>
                  <span className="label-input100">{strings('common.email')}</span>
                  <input className={'input100' + (username !== '' ? ' has-val' : '')} type="text" placeholder={strings('login.typeYour') + strings('common.email').toLowerCase()}
                    onChange={event => changeUsername(event.target.value)} />
                  <span className="focus-input100 email"></span>
                </div>
                <div className={'wrap-input100 last' + (passwordError ? ' alert-validate' : '')} data-validate={strings('login.password') + strings('login.isRequired')}>
                  <span className="label-input100">{strings('login.password')}</span>
                  <input className={'input100' + (password !== '' ? ' has-val' : '')} type="password" placeholder={strings('login.typeYour') + strings('login.password').toLowerCase()}
                    onChange={event => changePassword(event.target.value)} />
                  <span className="focus-input100 password"></span>
                </div>
                <div className="textRight forgotPw">
                  <a href="/forgot-password">
                    {strings('login.forgotPassword')}
                  </a>
                </div>
                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className={'login100-form-btn' + (submitting ? ' disabled' : '')} onClick={(event) => checkData(event)}>
                      {strings('login.title')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}