import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils.js';
import { CREATE_EXPOSANT, EVENT_YEARS, GET_EXPOSANTS_LIST, GET_PROMOTION_CODES } from '../../js/endpoints.js';
import Pagination from '../../components/Pagination.js'
import { strings } from '../../components/Language.js';
import Multiselect from '../../components/inputs/Multiselect.js';
import Loading from '../../components/Loading.js';
import { useParamsContext } from '../../js/ParamsContext.js';
import Popup from '../../components/Popup.js';
import { validateFields } from '../../js/validator.js';
import Input from '../../components/inputs/Input.js';
import DisplayPrice from '../../components/DisplayPrice.js';
import { setTooltipsPosition } from '../../js/Common.js';

const NUM_ROWS = 30;
const emptyFilter = {
  eventId: null,
  name: ''
}
const emptyExposant = {
  eventId: null,
  eventIdRequired: true,
  eventIdObjType: 'number',
  eventIdError: false,
  name: '',
  nameRequired: true,
  nameObjType: 'string',
  nameError: false
}
const doneTypingInterval = 500;
let typingTimer;

const COLUMNS = [strings('exposants.id'), strings('entrances.name'), strings('exposants.actions')]
export default function Exposants() {
  const { setParams } = useParamsContext();
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([])
  const [elementList, setElementList] = useState([]);
  const [filter, setFilter] = useState(emptyFilter);
  const [resetPagination, setResetPagination] = useState(false);
  const [codesPopup, setCodesPopup] = useState(false);
  const [exposantCodes, setExposantCodes] = useState([]);
  const [newExposantPopup, setNewExposantPopup] = useState(false);
  const [newExposant, setNewExposant] = useState(emptyExposant);
  const [submitting, setSubmitting] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [openedCode, setOpenedCode] = useState(null);
  const [exposantError, setExposantError] = useState(null);

  const changeFilter = useCallback((field, newValue) => {
    const newFilter = { ...filter };
    newFilter[field] = newValue
    setFilter(newFilter);
    if (field === 'name') {
      clearTimeout(typingTimer);

      typingTimer = setTimeout(function () {
        setResetPagination(true);
      }, doneTypingInterval);
    } else {
      setResetPagination(true);
    }
  }, [filter]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });
      request(EVENT_YEARS).then(jsonObject => {
        setYears(jsonObject.list)

        const defaultEvent = jsonObject.list.filter(y => y.label === new Date().getFullYear().toString());
        let eventId;
        if (defaultEvent.length === 1) {
          eventId = defaultEvent[0].value;
        } else {
          eventId = jsonObject.list[jsonObject.list.length - 1].value;
        }

        emptyExposant.eventId = eventId;
        changeFilter('eventId', eventId)
      })

      mounted.current = true;
    } else if (JSON.stringify(elementList) !== JSON.stringify(mounted.elementList)) {
      setTooltipsPosition();
      mounted.elementList = elementList;
    }
  }, [filter, changeFilter, setParams, elementList]);

  async function loadPage(page) {
    if (filter.eventId != null) {
      setLoading(true);
      setResetPagination(false);

      const body = {
        page: page,
        pageSize: NUM_ROWS,
        ...filter
      }

      return request(GET_EXPOSANTS_LIST, body).then(jsonObject => {
        setElementList(jsonObject.elements);
        setLoading(false);
        return jsonObject;
      })
    }
  }

  function openCodesPopup(exposant) {
    setLoading(true);
    request(GET_PROMOTION_CODES, { exposantId: exposant.id }).then(jsonObject => {
      setCodesPopup(true);
      setExposantCodes(jsonObject.promotionCodeList);
      setLoading(false);
    })
  }

  function openCreateExposant() {
    setNewExposant(emptyExposant);
    setSentSuccess(false);
    setNewExposantPopup(true);
  }

  function createExposant() {
    setExposantError(null);
    if (validateFields(newExposant)) {
      setSubmitting(true);
      request(CREATE_EXPOSANT, { ...newExposant }).then(responseJson => {
        setSubmitting(false);
        if (responseJson.httpStatus === 200) {
          setResetPagination(true);
          setSentSuccess(true);
        } else {
          setExposantError(responseJson.value);
        }
      })
    }
    setNewExposant({ ...newExposant });
  }


  return (
    <HelmetProvider>
      <div className='menuMarg'>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/logged/exposants.css" />
          <link rel="stylesheet" type="text/css" href="/css/table.css" />
        </Helmet>
        <Loading isLoading={loading} />

        <div className='maxW1600'>

          <Multiselect id="eventId" options={years} value={filter.eventId} className='selectEvent' multiple={false}
            onChange={newValue => changeFilter('eventId', newValue)} label={strings('dashboard.event')} />

          <div className="filterRow">
            <div className='filterField'>
              <Input label={strings('entrances.name')} onChange={(newValue) => changeFilter('name', newValue)} value={filter.name} />
            </div>
            <div className='filterFieldRight'>
              <button className='actionBtn' onClick={openCreateExposant}><i className='icon-plus fSize20 blue' /></button>
            </div>
          </div>

          <div className='tableScroll'>
            <table className="colorTable darkBlueTable">
              <thead>
                <tr>
                  {COLUMNS.map(column =>
                    <th key={column}>
                      {column}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {elementList.length === 0 ?
                  <tr><td colSpan={COLUMNS.length}>{strings('visited.noData')}</td></tr>
                  :
                  null}
                {elementList.map(exposant =>
                  <tr key={exposant.id}>
                    <td>{exposant.id}</td>
                    <td>{exposant.name}</td>
                    <td>
                      <div className="tooltip">
                        <button onClick={() => openCodesPopup(exposant)} className='actionBtn blue fSize20'><i className='icon-eye1' /></button>
                        <div className="tooltiptext">
                          {strings('exposants.viewCodes')}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination resetPagination={resetPagination}
            loadPage={loadPage}
            pageSize={NUM_ROWS} />
        </div>
      </div>

      <Popup id='codesPopup' opened={codesPopup} setOpened={setCodesPopup}>
        <h2 className='centered blue'>{strings('exposants.promotionCodes')}</h2>
        <div className='scrollable'>
          {exposantCodes.map(exposantCode =>
            <div key={exposantCode.id} className='marg20'>
              <p className='noMarg bold'>{exposantCode.name}:</p>
              <p className='noMarg'>{exposantCode.code} <button onClick={() => openedCode === exposantCode.id ? setOpenedCode(null) : setOpenedCode(exposantCode.id)} className="actionBtn blue">
                <span className='bold'><DisplayPrice price={exposantCode.price} currency={exposantCode.currency} /> </span>
                {exposantCode.used != null && exposantCode.maxPeople != null ?
                  <span>({exposantCode.used}/{exposantCode.maxPeople}) </span>
                  :
                  null
                }
                {exposantCode.entranceList.length > 0 ?
                  <i className={'icon-down-arrow2' + (openedCode === exposantCode.id ? ' rotate180' : '')} />
                  :
                  null
                }
              </button>
              </p>
              {exposantCode.entranceList.length > 0 && openedCode === exposantCode.id ?
                <div>
                  {exposantCode.entranceList.map(entrance =>
                    <div className='user fSize13'>
                      <p className='bold noMarg'>{entrance.name} {entrance.lastName} (<span>{entrance.email}</span>)</p>
                      <p className='noMarg'>{entrance.business}: <span>{entrance.profesion}</span></p>
                    </div>
                  )}
                </div>
                :
                null
              }
            </div>
          )}
        </div>

        <div className="ticketsInfoContainer noMargTop">
          <button onClick={() => setCodesPopup(false)} className='button'>{strings('common.close')}</button>
        </div>
      </Popup>

      <Popup id='createExposantPopup' opened={newExposantPopup} setOpened={setNewExposantPopup}>
        <h2 className='centered blue'>{strings('exposants.createExposant')}</h2>
        {sentSuccess ?
          <div className='centered'>
            <span className="alert alert-success centered">
              <i className="icon-checked" /> {strings('exposants.exposantCreated')}
            </span>
          </div>
          :
          <div className='centered inputFields'>
            <Multiselect options={years} value={newExposant.eventId} error={newExposant.eventIdError} multiple={false}
              onChange={newValue => setNewExposant({ ...newExposant, eventId: newValue })} label={strings('dashboard.event')} />
            <Input error={newExposant.nameError} label={strings('entrances.name')} value={newExposant.name} className='w100'
              onChange={newValue => setNewExposant({ ...newExposant, name: newValue })} />
          </div>
        }
        {exposantError ?
          <p className='red centered'>{exposantError}</p>
          :
          null
        }

        {sentSuccess ?
          <div className="ticketsInfoContainer noMargTop">
            <button onClick={() => setNewExposantPopup(false)} className='button'>{strings('common.close')}</button>
          </div>
          :
          <div className="ticketsInfoContainer noMargTop">
            <button onClick={() => createExposant()} className={'button' + (submitting ? ' disabled' : '')} disabled={submitting}>{strings('entrances.send')}</button>
          </div>
        }
      </Popup>
    </HelmetProvider >
  );
}
