import React, { createContext, useContext, useState } from 'react';

const ParamsContext = createContext();

export const useParamsContext = () => useContext(ParamsContext);

export const ParamsProvider = ({ children }) => {
    const [params, setParams] = useState({});

    return (
        <ParamsContext.Provider value={{ params, setParams }}>
            {children}
        </ParamsContext.Provider>
    );
};