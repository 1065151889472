import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils';
import { GALLERY, GALLERY_YEARS } from '../../js/endpoints';
import { strings } from '../../components/Language';
import ImageSlider from '../../components/ImageSlider';
import Multiselect from '../../components/inputs/Multiselect';
import { useParamsContext } from '../../js/ParamsContext';

const offset = 500;

export default function Gallery() {
  const [eventId, setEventId] = useState(null)
  const [years, setYears] = useState([])
  const [files, _setFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { setParams } = useParamsContext();

  const mounted = useRef();
  const setFiles = useCallback(data => {
    mounted.files = data;
    _setFiles(data);
  }, [_setFiles]);

  const previewImage = useCallback(async (index) => {
    if (index == null) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setCurrentIndex(index);
  }, []);

  const changeEvent = useCallback(async (newEventId) => {
    setEventId(newEventId);

    request(GALLERY, { eventId: newEventId }).then(jsonObject => {
      if (jsonObject.httpStatus === 200) {
        setFiles(jsonObject.files.map(file => process.env.REACT_APP_HTTPD_HOSTNAME + file))
        mounted.scrollLoaded = false;
      }
    });
  }, [setFiles]);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.getElementById('galleryContainer').getElementsByClassName('picture');
      for (let element of elements) {
        const position = element.getBoundingClientRect();

        if (window.scrollY + window.innerHeight + offset >= position.top
          || window.scrollY - offset >= position.bottom) {
          const img = element.getElementsByTagName('img')[0];
          if (img.getAttribute('src') == null) {
            img.setAttribute('src', img.getAttribute('data-src'));
            img.removeAttribute('data-src');
          }
        }
      }
    };

    if (!mounted.current) {
      setParams({ showMenuBar: true });
      window.addEventListener('scroll', handleScroll);

      request(GALLERY_YEARS).then(jsonObject => {
        setYears(jsonObject.list)

        const defaultEvent = jsonObject.list.filter(y => y.label === new Date().getFullYear().toString());
        let eventId;
        if (defaultEvent.length === 1) {
          eventId = defaultEvent[0].value;
        } else {
          eventId = jsonObject.list[jsonObject.list.length - 1].value;
        }

        changeEvent(eventId);
      })

      mounted.current = true;
    } else if (!mounted.scrollLoaded && files.length > 0) {
      handleScroll();
      mounted.scrollLoaded = true;
    }
  }, [setFiles, files, changeEvent, setParams]);

  return (
    <HelmetProvider>
      <div className='pageContainer'>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/gallery.css" />
        </Helmet>

        <div id="gallery" className="section">
          <div className='sectionContainer centered'>
            <h2 className="title">
              {strings('menu.images')}
            </h2>

            <div style={years.length <= 1 ? { display: 'none' } : null}>
              <Multiselect id="eventId" options={years} value={eventId} className={'inputElement'} multiple={false}
                onChange={newValue => changeEvent(newValue)} label={strings('dashboard.event')} />
            </div>

            <div id='galleryContainer' className='galleryContainer'>
              <div className='galleryWrap'>
                {files.map((file, index) =>
                  <div key={index} className='picture' onClick={() => previewImage(index)}>
                    <img key={`${file}-${index}`} alt='file' data-src={file} />
                    <div className='pictureOverlay' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {currentIndex != null ?
          <ImageSlider images={files} index={currentIndex} onClose={() => previewImage(null)} />
          :
          null
        }
      </div>
    </HelmetProvider>
  );
}