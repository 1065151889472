import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils.js';
import { GET_EMAILS, PREVIEW_EMAIL, SAVE_EMAIL } from '../../js/endpoints.js';
import Loading from '../../components/Loading.js';
import { useParamsContext } from '../../js/ParamsContext.js';
import { strings } from '../../components/Language.js';
import Switch from '../../components/inputs/Switch.js';
import CodeEditor from '../../components/inputs/CodeEditor.js';

export default function Emails() {
  const { setParams } = useParamsContext();
  const [loading, setLoading] = useState(true);
  const [emailList, setEmailList] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [editingEmail, setEditingEmail] = useState(null);
  const [previewEmail, setPreviewEmail] = useState(null);
  const [mobileVersion, setMobileVersion] = useState(false);
  const [savedSuccess, setSavedSuccess] = useState(false);

  const subjectRef = useRef();
  const contentRef = useRef();

  const loadPage = useCallback(component => {
    request(GET_EMAILS, { component: component }).then(jsonObject => {
      if (component) {
        setComponentList(jsonObject.list);
      } else {
        setEmailList(jsonObject.list);
      }
      setLoading(false)
    })
  }, [setComponentList, setEmailList, setLoading]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });
      loadPage(false);
      loadPage(true);

      mounted.current = true;
    }
  }, [setParams, loadPage]);

  function loadEmailPreview(email) {
    request(PREVIEW_EMAIL, { id: email.id }).then(jsonObject => {
      setEditingEmail(null);
      setPreviewEmail(jsonObject);
      setLoading(false)
    })
  }

  function editEmail(email) {
    setPreviewEmail(null);
    setEditingEmail(email);
  }

  function getPreview() {
    let preview = previewEmail?.body;
    if (preview != null) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(preview, 'text/html');

      const emailName = document.createElement('h2');
      emailName.className = 'emailName';
      emailName.textContent = previewEmail.name;

      const emailSubject = document.createElement('h2');
      emailSubject.className = 'emailSubject';
      emailSubject.textContent = strings('emails.subject') + ': ' + previewEmail.subject;

      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = '/css/logged/email-iframe.css';

      const anchors = doc.querySelectorAll('a')
      anchors.forEach((anchor) => {
        anchor.setAttribute('target', '_blank');
      });

      doc.body.insertBefore(emailSubject, doc.body.firstChild);
      doc.body.insertBefore(emailName, doc.body.firstChild);
      doc.head.appendChild(linkElement);

      return doc.documentElement.outerHTML;
    } else if (previewEmail?.exception != null) {
      preview = previewEmail.exception
    }

    return preview;
  }

  function changeEmail(key, newValue) {
    setEditingEmail({ ...editingEmail, [key]: newValue })
  }

  function saveEmail() {
    request(SAVE_EMAIL, { ...editingEmail, id: { id: editingEmail.id } }).then(jsonObject => {
      if (jsonObject.httpStatus === 200) {
        setSavedSuccess(true);
        setTimeout(() => setSavedSuccess(false), 3000);
      }

      loadPage(jsonObject.component)
    })
  }

  return (
    <HelmetProvider>
      <div className='menuMarg'>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/logged/emails.css" />
        </Helmet>
        <Loading isLoading={loading} />

        <div className='maxW1600'>
          <div className='col-3 emailsCol'>
            <div className="tabLinkBox">
              <div className="linkTabs w100">
                <div className="tabNav">
                  <ul>
                    <li className={'tab' + (selectedTab === 1 ? ' active' : '')}>
                      <label className="centered floatR" onClick={() => setSelectedTab(1)}>{strings('emails.emails')}</label>
                    </li>
                    <li className={'tab' + (selectedTab === 2 ? ' active' : '')}>
                      <label className="centered floatR" onClick={() => setSelectedTab(2)}>{strings('emails.components')}</label>
                    </li>
                  </ul>
                </div>
                <div className="tabLinks">
                  <div className={'tabContent' + (selectedTab === 1 ? ' active' : '')}>
                    <ul>
                      {emailList.map((email, index) =>
                        <li key={index} className='mail-list'>
                          <strong className='fSize14'>
                            {email.name}:
                            <button className="cPointer" onClick={() => editEmail(email)}>
                              <i className="icon icon-edit green" />
                            </button>
                          </strong>
                          <div className="mail-sublist fSize14">
                            {strings('emails.preview')}:
                            <button onClick={() => loadEmailPreview(email)} className="cPointer blue">FR</button>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className={'tabContent' + (selectedTab === 2 ? ' active' : '')}>
                    <ul>
                      {componentList.map((component, index) =>
                        <li key={index} className='mail-list'>
                          <strong className='fSize14'>
                            {component.name}
                            <button className="cPointer" onClick={() => editEmail(component)}>
                              <i className="icon icon-edit green" />
                            </button>
                          </strong>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-9'>
            {previewEmail ?
              <div>
                <Switch label={'Version mobile'} value={mobileVersion} onChange={(newValue) => setMobileVersion(newValue)} className='centered' />
                <iframe title='MailContent' className={'mailContent' + (mobileVersion ? ' mobile' : '')} srcDoc={getPreview()} style={previewEmail == null ? { display: 'none' } : null} />
              </div>
              :
              editingEmail ?
                <div>
                  <h2 className='centered blue'>{editingEmail.name}</h2>

                  {editingEmail.fileType === 'velocity' ?
                    [<h3 key='title' className='fSize16'>{strings('emails.subject')}</h3>,
                    <CodeEditor key='content' ref={subjectRef} value={editingEmail.subject} onChange={(newValue) => changeEmail('subject', newValue)} mode='javascript' height={50} />]
                    :
                    null
                  }

                  <h3 className='fSize16'>{strings('emails.content')}</h3>
                  <CodeEditor ref={contentRef} value={editingEmail.content} onChange={(newValue) => changeEmail('content', newValue)} mode='javascript' height={600} />

                  <div className='centered margTop10'>
                    <button className='button' onClick={saveEmail}>{strings('common.save')}</button>
                  </div>
                  {savedSuccess ?
                    <div className='centered margTop10'>
                      <div className='minSeparator' />
                      <span className="alert alert-success centered">
                        <i className="icon-checked" /> {strings('emails.savedSuccess')}
                      </span>
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
            }
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}
