import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils';
import { VIDEOS_YEARS, GALLERY_VIDEO } from '../../js/endpoints';
import { strings } from '../../components/Language';
import Multiselect from '../../components/inputs/Multiselect';
import { useParamsContext } from '../../js/ParamsContext';

export default function GalleryVideo() {
  const [eventId, setEventId] = useState(null)
  const [years, setYears] = useState([])
  const [videos, setVideos] = useState([]);
  const { setParams } = useParamsContext();

  const changeEvent = useCallback(async (newEventId) => {
    setEventId(newEventId);

    request(GALLERY_VIDEO, { eventId: newEventId }).then(jsonObject => {
      if (jsonObject.httpStatus === 200) {
        setVideos(jsonObject.videos)
      }
    });
  }, [setVideos]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });
      request(VIDEOS_YEARS).then(jsonObject => {
        setYears(jsonObject.list)

        const defaultEvent = jsonObject.list.filter(y => y.label === new Date().getFullYear().toString());
        let eventId;
        if (defaultEvent.length === 1) {
          eventId = defaultEvent[0].value;
        } else {
          eventId = jsonObject.list[jsonObject.list.length - 1].value;
        }

        changeEvent(eventId);
      })

      mounted.current = true;
    }
  }, [changeEvent, setParams]);


  return (
    <HelmetProvider>
      <div className='pageContainer'>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/videos.css" />
        </Helmet>

        <div id="videos" className="section">
          <div className='sectionContainer centered'>
            <h2 className="title">
              {strings('menu.videos')}
            </h2>

            <div style={years.length <= 1 ? { display: 'none' } : null}>
              <Multiselect id="eventId" options={years} value={eventId} className={'inputElement'} multiple={false}
                onChange={newValue => changeEvent(newValue)} label={strings('dashboard.event')} />
            </div>

            <div id='videosContainer' className='videosContainer'>
              <div className='videosWrap'>
                {videos.map((video, index) =>
                  <div key={index} className='video'>
                    <iframe src={video.link} title={video.name} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}