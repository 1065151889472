import { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GET_PAYMENT, PREPARE_PAYMENT, getUrlParameter } from '../../js/endpoints';
import { request } from '../../js/APIUtils';
import { strings } from '../../components/Language';
import DisplayPrice from '../../components/DisplayPrice';
import Loading from '../../components/Loading';
import { addParameterToUrl } from '../../js/Common';

export default function Payment() {
  const [payment, setPayment] = useState({});
  const [entrances, setEntrances] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showOldPrice, setShowOldPrice] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerCode, setProviderCode] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      request(GET_PAYMENT, {
        id: getUrlParameter('id')
      }).then(async (jsonObject) => {
        if (jsonObject.payment) {
          setPayment(jsonObject.payment);
          setEntrances(jsonObject.payment.entrances);
          setProviders(jsonObject.providerList != null ? jsonObject.providerList : []);
          setSelectedProvider(jsonObject.providerList != null && jsonObject.providerList.length > 0 ? jsonObject.providerList[0].id : null);
          setShowOldPrice(jsonObject.payment.originalAmount !== jsonObject.payment.amount);
        }
      });

      mounted.current = true;
    }
  }, []);

  function goToPay(id) {
    setSubmitting(true);
    request(PREPARE_PAYMENT, {
      id: id,
      providerId: selectedProvider
    }).then(async (jsonObject) => {
      if (jsonObject.httpStatus === 201) {
        addParameterToUrl('id', jsonObject.code);
        goToPay(jsonObject.code);
      } else {
        if (jsonObject.action === 'FORM') {
          const form = document.createElement('form');
          form.id = 'auxForm';
          form.action = jsonObject.url;
          form.method = 'POST';
          form.enctype = 'application/x-www-form-urlencoded';

          for (let entry of Object.keys(jsonObject.params)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.id = entry;
            input.name = entry;
            input.value = jsonObject.params[entry];
            form.appendChild(input);
          }

          document.body.appendChild(form);
          form.submit();
        } else if (jsonObject.action === 'URL') {
          window.location.href = jsonObject.url;
        } else {
          setProviderCode(jsonObject.code);
          setSubmitting(false);
        }
      }
    });
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/payment.css" />
        </Helmet>
        <Loading isLoading={submitting} />

        <div id="payment" className="row section paymentSection">
          <div className="paymentOverlay"></div>
          <div className="sectionContainer paymentContainer">
            <div className="paymentTitleContainer">
              <h2 className="paymentTitle">
                <span className="paymentSubTitle">{strings('payment.title')}</span>
                {strings('payment.subtitle')}
              </h2>

              <p className="paymentSvg">
                <svg width="71px" height="11px">
                  <path fillRule="evenodd"
                    d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                  </path>
                </svg>
              </p>
            </div>

            <div className="paymentPricesWrapContainer">
              <div className="paymentPricesWrap">
                <div className="paymentPricesContainer">
                  <h2 className="paymentPricesTitle">{strings('payment.tickets')}</h2>

                  {entrances.map((entrance, index) =>
                    <div key={index} className="entrance">
                      <div className="col-9">
                        <p className="label">{strings('common.entrance')} {index + 1} {entrance.workshops?.length > 0 ? strings('payment.with') + ':' : ''}</p>
                        {entrance.workshops?.length > 0 ?
                          <ul>
                            {entrance.workshops.map(workshop => <li className='label'>{workshop.name}</li>)}
                          </ul>
                          :
                          null}
                        <p className="entranceData">{entrance.name + ' ' + entrance.lastName + ' (' + entrance.phone + ')'}</p>
                        <p className="entranceData">{entrance.email}</p>
                      </div>
                      <div className="col-3">
                        {entrance.entranceUrl != null ?
                          <a target='_blank' href={entrance.entranceUrl} rel="noreferrer"><i className="icon icon-download" /></a>
                          :
                          null
                        }
                      </div>
                    </div>
                  )}

                  {payment ?
                    <div className="w100 disFlex">
                      <div className="totalAmountContainer">
                        <p className="totalAmount"><span className={'totalLabel' + (showOldPrice ? ' oldPrice' : '')}>{strings('common.total')}: </span><DisplayPrice price={payment.originalAmount} currency={payment.currency} /><span
                          id="promotionCodePrice" style={showOldPrice ? null : { display: 'none' }}> &#8594; <DisplayPrice price={payment.amount} currency={payment.currency} /></span></p>
                      </div>
                    </div>
                    :
                    null}
                </div>
              </div>
            </div>

            {payment?.paid ?
              null
              :
              [providers.length > 0 ?
                <div key={0} className="providersWrapContainer">
                  <div className="providersWrap">
                    <div className="providersContainer">
                      <h2 className="providersTitle">{strings('payment.paymentMode')}</h2>

                      <div className="w100 disFlex">
                        {providers.map((provider) =>
                          <div key={provider.id} className="col-6" style={providerCode != null && provider.id !== selectedProvider ? { display: 'none' } : null}>
                            <input className="inBlock cardPay" type="radio" id={provider.id} name="providerId" value={provider.id} checked={provider.id === selectedProvider}
                              onChange={() => setSelectedProvider(provider.id)} />
                            <label className="inBlock labelPayment cardPay" htmlFor={provider.id}>
                              <img className="inBlock paymentImg" src={provider.logoUrl} alt="" />
                            </label>
                            <br />
                          </div>
                        )}
                        {providerCode != null ?
                          <div className="col-6">
                            <p className="label">{strings('payment.code')}</p>
                            <input id="code" className="inputElement textRight" type="text" readOnly="readOnly" value={providerCode} />
                          </div>
                          :
                          null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
                ,
              providerCode != null ?
                null
                :
                <div key={1} className="paymentLinkContainer">
                  <button onClick={() => goToPay(getUrlParameter('id'))} className={'paymentsBtn' + (submitting ? ' disabled' : '')}>{providers.length === 0 ? strings('common.continue') : strings('payment.goToPay')}</button>
                </div>]
            }
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
