import { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GET_PAYMENT_RESPONSE, getUrlParameter } from '../../js/endpoints';
import { request } from '../../js/APIUtils';
import { strings } from '../../components/Language';
import Loading from '../../components/Loading';

export default function PaymentResponse() {
  const [result, setResult] = useState(null);
  const [entrances, setEntrances] = useState([]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      request(GET_PAYMENT_RESPONSE, {
        id: getUrlParameter('pId')
      }).then(async (jsonObject) => {
        setResult(jsonObject.paymentDone);
        if (jsonObject.paymentDone) {
          setEntrances(jsonObject.payment.entrances);
        }
      });

      mounted.current = true;
    }
  }, [result]);

  function getWorkshopNames(workshops) {
    let name = '';
    for (let workshop of workshops) {
      if (name !== '') {
        name += strings('common.andPrep');
      }
      name += workshop.name;
    }
    return name;
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/payment-response.css" />
        </Helmet>
        <Loading isLoading={result == null} />

        <div id="payment" className="row section paymentSection">
          <div className="paymentOverlay"></div>
          <div className="sectionContainer paymentContainer">
            <div className="paymentTitleContainer">
              <h2 className="paymentTitle">
                <span className="paymentSubTitle">{strings('payment.confirmTitle')}</span>
                {strings('payment.confirmSubtitle')}
              </h2>

              <p className="paymentSvg">
                <svg width="71px" height="11px">
                  <path fillRule="evenodd"
                    d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                  </path>
                </svg>
              </p>
            </div>

            <div className="paymentPricesWrapContainer">
              <div className="paymentPricesWrap">
                <div className="paymentPricesContainer">
                  {result ?
                    <div id="ok-message">
                      <h3 id="iconTitle" className="iconTitle ok centered">
                        <i className="icon icon-checkmark-circle" />
                      </h3>
                      <h3 id="textTitle" className="textTitle centered">{strings('home.confirmDesc1')}</h3>
                      <p className="text fSize13">{strings('home.confirmDesc2')}</p>

                      <div id="tickets" className="w100 disFlex">
                        {entrances.map((entrance, index) =>
                          <div key={index} className="entrance">
                            <div className="col-9">
                              <p className="label">{strings('common.entrance')} {index + 1} {entrance.workshops?.length > 0 ? strings('payment.with') + ': ' + getWorkshopNames(entrance.workshops) : ''}</p>
                              <p className="entranceData">{entrance.name + ' ' + entrance.lastName + ' (' + entrance.phone + ')'}</p>
                              <p className="entranceData">{entrance.email}</p>
                            </div>
                            <div className="col-3">
                              {entrance.entranceUrl != null ?
                                <a target='_blank' rel="noreferrer" href={entrance.entranceUrl}><i className="icon icon-download" /></a>
                                :
                                null
                              }
                            </div>
                          </div>
                        )}
                      </div>

                      <p className="text fSize13">{strings('payment.confirmDesc1')}</p>

                      <p className="text fSize13">{strings('payment.confirmDesc2')}</p>
                      <p className="text fSize13">{strings('payment.confirmDesc3')}</p>

                    </div>
                    :
                    result != null ?
                      <div id="ko-message">
                        <h3 id="iconTitle" className="iconTitle ko centered">
                          <i className="icon icon-cross-circle" />
                        </h3>
                        <h3 id="textTitle" className="textTitle centered">{strings('payment.confirmError1')}</h3>
                        <p className="subtitle centered">{strings('payment.confirmError2')}</p>
                        <p className="text centered fSize13">{strings('payment.confirmError3')}</p>
                      </div>
                      :
                      <div />
                  }

                  <div className="minDivider" />

                  <div className="bottom-link centered">
                    <a href="/" className="continue verticalMiddle">{strings('common.continue')}</a>
                  </div>
                  <div className="minDivider" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}