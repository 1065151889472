import { useState } from 'react';
import '../../css/checkbox.css';

export default function CheckBox({ label, value, onChange }) {
  const [defaultValue, setDefaultValue] = useState(value || false);

  function changeValue() {
    onChange(!defaultValue)
    setDefaultValue(!defaultValue);
  }

  return (<label className="parentChekboxLabel">
    <div className="customCheckBox">
      <label className="checkboxLabel verticalItem alignL">
        {label}
        <input type="checkbox" checked={defaultValue} className="checkBoxInput" value="true" onChange={changeValue} />
        <span className="checkmark" />
      </label>
    </div>
  </label>
  );
}
