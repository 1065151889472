import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { strings } from '../components/Language';
import { request } from '../js/APIUtils';
import { RESET_PASSWORD, getUrlParameter } from '../js/endpoints';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

export default function ResetPassword() {
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordConfirmError, setPasswordConfirmError] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const token = getUrlParameter('token')

  const navigate = useNavigate();
  const goToLogin = () => navigate('/login');

  function changePassword(newValue) {
    setPassword(newValue);
    setPasswordError(false);
  }

  function changePasswordConfirm(newValue) {
    setPasswordConfirm(newValue);
    setPasswordConfirmError(false);
  }

  function checkData(event) {
    event.preventDefault();
    let validPassword = true;

    // Verificar longitud (entre 6 y 50 caracteres)
    validPassword &= password.length >= 6;
    validPassword &= password.length <= 50;

    // Verificar que contenga al menos un dígito
    validPassword &= /\d/.test(password);

    // Verificar que contenga al menos una letra mayúscula
    validPassword &= /[A-Z]/.test(password);

    // Verificar que contenga al menos una letra minúscula
    validPassword &= /[a-z]/.test(password);
    const validPasswordConfirm = passwordConfirm.length > 0 && password === passwordConfirm;

    setPasswordError(!validPassword);
    setPasswordConfirmError(!validPasswordConfirm);

    if (validPassword && validPasswordConfirm) {
      setSubmitting(true);
      const body = {
        token: token,
        password: password,
        passwordConfirm: passwordConfirm
      }
      request(RESET_PASSWORD, body).then(jsonObject => {
        goToLogin()
      })
    }
    return false
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/login.css" />
        </Helmet>
        <Loading isLoading={submitting} />

        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form">
                <span className="login100-form-title">
                  {strings('login.resetPasswordTitle')}
                </span>
                <p className="label-invalidLogin" style={{ visibility: 'visible' }}>{strings('login.passwordRequirements')}</p>
                <div className={'wrap-input100' + (passwordError ? ' alert-validate' : '')} data-validate={strings('login.password') + strings('login.isRequired')}>
                  <span className="label-input100">{strings('login.password')}</span>
                  <input className={'input100' + (password !== '' ? ' has-val' : '')} type="password" placeholder={strings('login.typeYour') + strings('login.password').toLowerCase()}
                    onChange={event => changePassword(event.target.value)} />
                  <span className="focus-input100 password"></span>
                </div>
                <div className={'wrap-input100 last' + (passwordConfirmError ? ' alert-validate' : '')} data-validate={strings('login.passwordConfirm') + strings('login.isRequired')}>
                  <span className="label-input100">{strings('login.passwordConfirm')}</span>
                  <input className={'input100' + (passwordConfirm !== '' ? ' has-val' : '')} type="password" placeholder={strings('login.typeYour') + strings('login.passwordConfirm').toLowerCase()}
                    onChange={event => changePasswordConfirm(event.target.value)} />
                  <span className="focus-input100 password"></span>
                </div>
                <div className="textRight forgotPw" />
                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className={'login100-form-btn' + (submitting ? ' disabled' : '')} onClick={(event) => checkData(event)}>
                      {strings('common.save')}
                    </button>
                  </div>
                </div>
                <p className="centered">
                  <a href="/login">{strings('login.goBack')}</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}