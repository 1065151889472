import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Loading from '../../components/Loading.js';
import { request } from '../../js/APIUtils.js';
import { GET_ENTRANCES_BY_EMAIL, GET_SECTOR_OPTIONS, GET_WORKSHOP, REGISTER_PAYMENT, getEventId } from '../../js/endpoints.js';
import { useParamsContext } from '../../js/ParamsContext.js';
import EntranceForm, { EMPTY_TICKET, isValidEntrance } from '../../components/EntranceForm.js';
import { strings } from '../../components/Language.js';
import { formatDate } from '../../js/Common.js';
import TermsAndConditions from '../../components/TermsAndConditions.js';
import DisplayPrice from '../../components/DisplayPrice.js';
import WorkshopSchedule from '../../components/WorkshopSchedule.js';
import { isValidEmail } from '../../js/validator.js';
import moment from 'moment/min/moment-with-locales'
import Popup from '../../components/Popup.js';

let noEntranceTimer;
let containsEntranceTimer;
export default function Workshop() {
  const { workshopName } = useParams();
  const { setParams } = useParamsContext();
  const [loading, setLoading] = useState(true);
  const [hasEntrance, setHasEntrance] = useState(false);
  const [workshop, setWorkshop] = useState({ name: workshopName });
  const [ticket, setTicket] = useState({ ...EMPTY_TICKET });
  const [sectorOptions, setSectorOptions] = useState([]);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openEntrances, setOpenEntrances] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [noEntrancesError, setNoEntrancesError] = useState(false);
  const [containsEntranceError, setContainsEntranceError] = useState(false);
  const [availableEntrances, setAvailableEntrances] = useState([]);
  const [selectedEntrance, setSelectedEntrance] = useState();
  const [noSelectedEntranceError, setNoSelectedEntranceError] = useState(false);
  const [sticky, setSticky] = useState(false);

  const checkTicket = useCallback(() => {
    let allValid = isValidEntrance(ticket);
    const auxTickets = [{ ...ticket }];

    const validTermsCheckbox = hasEntrance || document.getElementById('termsAndConditions').checked;
    setTermsAndConditionsError(!validTermsCheckbox);
    allValid = allValid && validTermsCheckbox;

    if (allValid) {
      setSubmitting(true);
      getEventId().then(eventId => {
        const body = {
          eventId: eventId,
          workshopId: workshop.id,
          people: auxTickets.length,
          entrances: auxTickets
        }

        request(REGISTER_PAYMENT, body).then(async (jsonObject) => {
          if (jsonObject.httpStatus === 200) {
            window.location.href = '/payment?id=' + jsonObject.id;
          }
        });
      })
    } else {
      setTicket({ ...ticket });
      setHasError(true);
    }
  }, [hasEntrance, ticket, workshop]);

  const submitEntrance = useCallback(() => {
    if (selectedEntrance) {
      setNoSelectedEntranceError(false);
      setTicket({ ...selectedEntrance });
      mounted.callCheck = true;
    } else {
      setNoSelectedEntranceError(true);
    }
  }, [selectedEntrance]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });
      request(GET_WORKSHOP, { workshopName: workshopName }).then(async (jsonObject) => {
        setWorkshop(jsonObject);
        setDisabled(jsonObject.currentPrice == null)
        setLoading(false);
      });

      request(GET_SECTOR_OPTIONS).then(async (jsonObject) => {
        setSectorOptions(jsonObject.sectorList);
      });
      mounted.current = true;
    } else if (hasError) {
      const y = document.querySelector('.error').getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });

      setHasError(false);
    } else if (mounted.selectEntrance) {
      submitEntrance();
      mounted.selectEntrance = false;
    } else if (mounted.callCheck) {
      checkTicket();
      mounted.callCheck = false;
    }
  }, [workshopName, setParams, setWorkshop, hasError, checkTicket, submitEntrance]);

  const checkSticky = () => {
    if (document.getElementById('subscribeBtn')) {
      setSticky(document.getElementById('subscribeBtn').getBoundingClientRect().top <= 0);
    }
  };

  window.addEventListener('scroll', checkSticky);

  function updateTicket(target) {
    const auxTicket = { ...ticket };
    auxTicket[target.id] = target.value;

    setTicket(auxTicket);
  }

  function findEntrance() {
    if (isValidEmail(ticket.email)) {
      setSubmitting(true);
      setShowEmailError(false);
      getEventId().then(eventId => {
        const body = {
          eventId: eventId,
          email: ticket.email,
          workshopId: workshop.id
        }

        request(GET_ENTRANCES_BY_EMAIL, body).then(async (jsonObject) => {
          if (jsonObject.httpStatus === 200) {
            setAvailableEntrances(jsonObject.entrances);
            if (jsonObject.entrances.length === 0) {
              setNoEntrancesError(true);
              clearTimeout(noEntranceTimer);
              noEntranceTimer = setTimeout(() => setNoEntrancesError(false), 2500);
            } else if (jsonObject.entrances.length === 1 && jsonObject.entrances[0].containsEntrance) {
              setContainsEntranceError(true);
              clearTimeout(containsEntranceTimer);
              containsEntranceTimer = setTimeout(() => setContainsEntranceError(false), 2500);
            } else if (jsonObject.entrances.length > 1) {
              setOpenEntrances(true);
            } else {
              setSelectedEntrance(jsonObject.entrances[0]);
              mounted.selectEntrance = true;
            }
          }
          setSubmitting(false);
        });
      })
    } else {
      setShowEmailError(true);
    }
  }

  function closePopup() {
    setOpenEntrances(false);
    setSelectedEntrance(null);
  }

  function isFuture() {
    if (workshop && workshop.date) {
      const targetDate = moment(workshop.date)
        .hour(workshop.start.hour)
        .minute(workshop.start.minute)
        .second(workshop.start.second)
        .millisecond(workshop.start.nano / 1000000);
      return targetDate.isAfter(moment());
    } else return false;
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/workshop.css" />
        </Helmet>
        <Loading isLoading={loading} />

        <div id="workshop" className="row section workshopSection">
          <div className="sectionContainer workshopContainer">
            <div className='col-6 centered'>
              <div className="workshopTitleContainer">
                <h2 className="workshopTitle">
                  {workshop.name}
                </h2>

                <div className='subscribe'>
                  <a href='#linkTabs' className='button'>{strings('billeterie.subscribe')}</a>
                </div>

                <div dangerouslySetInnerHTML={{ __html: workshop.description }} />
                <p>📅 {formatDate(workshop.date, 'dddd D MMMM YYYY')} - {formatDate(workshop.start, 'HH:mm')} {strings('common.toPrep')} {formatDate(workshop.end, 'HH:mm')}</p>
                <p>
                  <img className='priceImg' src='/img/price-tag.png' alt='Price' />
                  {workshop.maxPrice ?
                    [<span key={'price'} className='linethrough'><DisplayPrice currency={workshop.currency} price={workshop.maxPrice} /></span>,
                    <span key={'arrow'}> <i className='icon-arrow-right' /> </span>]
                    :
                    null}
                  {workshop.currentPrice != null ?
                    [<b key='price'><DisplayPrice currency={workshop.currency} price={workshop.currentPrice} /> </b>,
                    workshop.toPlaces ? strings('workshop.fistSold', { numEntrances: workshop.toPlaces }) : '']
                    :
                    strings('workshop.soldOut')}
                </p>

                {workshop.schedules?.length > 0 ?
                  <button onClick={() => setOpenSchedule(true)} className='button seeSchedule'>{strings('workshop.seeSchedule')}</button>
                  :
                  null
                }
              </div>
            </div>
            {isFuture() ?
              <div className='col-6 centered'>
                <div id="linkTabs">
                  <h2>{strings('workshop.alreadyEntrance')}</h2>
                  <div className='linkTabs'>
                    <div className="tabNav">
                      <ul>
                        <li className={'tab1' + (hasEntrance === false ? ' active' : '') + (submitting ? ' disabled' : '')} onClick={() => setHasEntrance(false)}>
                          <label className="centered">{strings('workshop.notYet')}</label>
                        </li>
                        <li className={'tab2' + (hasEntrance === true ? ' active' : '') + (submitting ? ' disabled' : '')} onClick={() => setHasEntrance(true)}>
                          <label className="centered">{strings('workshop.alreadyHave')}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {hasEntrance ?
                  <div className="hasEntrance ticketsInfoWrap">
                    <div className="ticketsInfoContainer">
                      <p className="label">{strings('common.email')} *</p>
                      <input type="email" id="email" className={'inputElement' + (disabled ? ' disabled' : '') + (showEmailError ? ' error' : '')} maxLength="150" defaultValue={ticket.email}
                        onChange={event => updateTicket(event.target)} disabled={disabled} />
                      {noEntrancesError ? <p className='red'>{strings('workshop.notFound')}</p>
                        : null}
                      {containsEntranceError ? <p className='red'>{strings('workshop.alreadyHaveEntrance')}</p>
                        : null}
                    </div>
                    <div className="ticketsInfoWrap">
                      <div className="ticketsInfoContainer noMargTop">
                        <button onClick={() => findEntrance()} className={'button' + (submitting || disabled ? ' disabled' : '')} disabled={disabled}>{strings('workshop.find')}</button>
                      </div>
                    </div>
                  </div>
                  :
                  <div id='entranceForm'>
                    <EntranceForm title={strings('workshop.subscribeWorkshop')} ticket={ticket} updateTicket={(element) => updateTicket(element)} sectorOptions={sectorOptions}
                      disabled={disabled} />
                    <div className="ticketsInfoWrap">
                      <div id='subscribeBtn' className="ticketsInfoContainer noMargTop">
                        <div className={'checkboxContainer' + (termsAndConditionsError ? ' error' : '')}>
                          <input className={disabled ? 'disabled' : ''} type="checkbox" id="termsAndConditions" />
                          <label className={disabled ? 'disabled' : ''} htmlFor="termsAndConditions">{strings('billeterie.termsConditionsStart')} <span className='linkText cPointer' onClick={() => setOpenModal(true)}>{strings('billeterie.termsConditionsLink')}</span> {strings('billeterie.termsConditionsEnd')}</label>
                        </div>
                        <button onClick={() => checkTicket()} className={'button' + (submitting || disabled ? ' disabled' : '')} disabled={disabled}>{strings('billeterie.subscribe')}</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              :
              null
            }

            {workshop.schedules?.length > 0 ?
              <div className='col-12 centered'>
                <button onClick={() => setOpenSchedule(true)} className='button seeSchedule'>{strings('workshop.seeSchedule')}</button>
              </div>
              :
              null
            }
            <div className='col-12'>
              <hr className='separatorLine' />
              <h2 className='centered'>{strings('workshop.teacher')}</h2>
              <div className="workshopPerson">
                <div className="imgContainer">
                  <img alt={workshop.teacherName} src={workshop.teacherPhoto?.replaceAll('{httpdHostname}', process.env.REACT_APP_HTTPD_HOSTNAME)} />
                </div>
                <p className="text bold centered">{workshop.teacherName}</p>
                {workshop.teacherFile ?
                  [<a key='link' href={workshop.teacherFile.replaceAll('{httpdHostname}', process.env.REACT_APP_HTTPD_HOSTNAME)}>{strings('workshop.seeBiography')}</a>,
                  <div key='separator' className='minSeparator' />]
                  :
                  null
                }
              </div>
            </div>
            <div className='col-12' dangerouslySetInnerHTML={{ __html: workshop.content }} />
          </div>
        </div>

        <WorkshopSchedule opened={openSchedule} setOpened={setOpenSchedule} schedules={workshop.schedules} />
        <TermsAndConditions opened={openModal} setOpened={setOpenModal} />
        <Popup id='entranceSelector' opened={openEntrances} setOpened={setOpenEntrances}>
          <h2 className='centered'>{strings('workshop.availableEntrances')}</h2>
          <div className='cardContainer scrollable'>
            {availableEntrances.map((entrance, index) =>
              <div key={index} className={'card' + (entrance.id === selectedEntrance?.id ? ' selected' : '')}>
                <button className={'card-body cPointer' + (entrance.containsEntrance ? ' disabled' : '')} onClick={entrance.containsEntrance ? null : () => setSelectedEntrance(entrance)}>
                  <div className='centered'>
                    <h4>{entrance.name} {entrance.lastName}</h4>
                    <p className="subtitle">{entrance.business}</p>
                    <p className="subtitle margBot20">{entrance.profesion}</p>
                    {entrance.containsEntrance ? <p className='red'>{strings('workshop.alreadyHaveEntrance')}</p> : null}
                  </div>
                </button>
              </div>
            )}
          </div>

          <div className="ticketsInfoContainer noMargTop">
            {noSelectedEntranceError ? <p className='red'>{strings('workshop.mustSelectEntrance')}</p> : null}
            {availableEntrances.filter(entrance => !entrance.containsEntrance).length > 0 ?
              <button onClick={() => submitEntrance()} className={'button' + (submitting || disabled ? ' disabled' : '')} disabled={disabled}>{strings('workshop.select')}</button>
              :
              <button onClick={() => closePopup()} className='button'>{strings('common.close')}</button>
            }
          </div>
        </Popup>

        <a className="goForm" href='#linkTabs' style={sticky ? null : { display: 'none' }}>
          <span>{strings('workshop.subscribe')}</span>
        </a>
      </div>
    </HelmetProvider >
  );
}
