import '../../css/input.css';

function Input({ value, onChange, className, label, error }) {
  return [value !== '' ?
    <label key='label' className='inputLabel'>{label}</label>
    :
    null,
  <input key='input' type='text' value={value} className={className + (error ? ' error' : '')} onChange={event => onChange(event.target.value)}
    placeholder={label} />]
}

export default Input;
