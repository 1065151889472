import { useEffect, useState } from "react";
import { strings } from "./Language";
import Popup from "./Popup";

export default function TermsAndConditions({ opened, setOpened }) {
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    if (opened) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }

    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [opened]);

  return (
    <Popup opened={opened} setOpened={setOpened}>
      <h1>{strings('terms.title', { year: year })}</h1>
      <p>{strings('terms.subtitle')}</p>

      <strong>{strings('terms.title1')}</strong>
      <p>{strings('terms.text1_1')}</p>
      <ul>
        <li dangerouslySetInnerHTML={{ __html: strings('terms.text1_2') }} />
        <li dangerouslySetInnerHTML={{ __html: strings('terms.text1_3') }} />
        <li dangerouslySetInnerHTML={{ __html: strings('terms.text1_4') }} />
        <li dangerouslySetInnerHTML={{ __html: strings('terms.text1_5') }} />
        <li dangerouslySetInnerHTML={{ __html: strings('terms.text1_6', { year: year }) }} />
        <li dangerouslySetInnerHTML={{ __html: strings('terms.text1_7') }} />
      </ul>
      <br />

      <strong>{strings('terms.title2')}</strong>
      <p>{strings('terms.text2_1')}</p>
      <p>{strings('terms.text2_2')}</p>

      <strong>{strings('terms.title3')}</strong>
      <p>{strings('terms.text3_1')}</p>
      <p>{strings('terms.text3_2')}</p>

      <strong>{strings('terms.title4')}</strong>
      <p>{strings('terms.text4_1')}</p>
      <p>{strings('terms.text4_2')}</p>

      <strong>{strings('terms.title5')}</strong>
      <p>{strings('terms.text5_1')}</p>
      <p>{strings('terms.text5_2')}</p>
      <p>{strings('terms.text5_3')}</p>

      <strong>{strings('terms.title6')}</strong>
      <p>{strings('terms.text6_1')}</p>

      <strong>{strings('terms.title7')}</strong>
      <p>{strings('terms.text7_1')}</p>

      <strong>{strings('terms.title8')}</strong>
      <p>{strings('terms.text8_1')}</p>
      <p>{strings('terms.text8_2')}</p>
      <ul>
        <li>{strings('terms.text8_3')}</li>
        <li>{strings('terms.text8_4')}</li>
        <li>{strings('terms.text8_5')}</li>
        <li>{strings('terms.text8_6')}</li>
        <li>{strings('terms.text8_7')}</li>
        <li>{strings('terms.text8_8')}</li>
        <li>{strings('terms.text8_9')}</li>
        <li>{strings('terms.text8_10')}</li>
        <li>{strings('terms.text8_11')}</li>
      </ul>
      <p>{strings('terms.text8_12')}</p>

      <strong>{strings('terms.title9')}</strong>
      <p>{strings('terms.text9_1')}</p>

      <strong>{strings('terms.title10')}</strong>
      <p>{strings('terms.text10_1')}</p>
      <p>{strings('terms.text10_2')}</p>
      <p>{strings('terms.text10_3')}</p>
      <p>{strings('terms.text10_4')}</p>
      <p>{strings('terms.text10_5')}</p>

      <strong>{strings('terms.title11')}</strong>
      <p>{strings('terms.text11_1')}</p>
      <p>{strings('terms.text11_2')}</p>

      <strong>{strings('terms.title12')}</strong>
      <p dangerouslySetInnerHTML={{ __html: strings('terms.text12_1') }} ></p>
      <p dangerouslySetInnerHTML={{ __html: strings('terms.text12_2') }} ></p>
      <p dangerouslySetInnerHTML={{ __html: strings('terms.text12_3') }} ></p>
      <p dangerouslySetInnerHTML={{ __html: strings('terms.text12_4') }} ></p>
      <p dangerouslySetInnerHTML={{ __html: strings('terms.text12_5') }} ></p>

      <strong>{strings('terms.title13')}</strong>
      <p>{strings('terms.text13_1')}</p>
      <p>{strings('terms.text13_2')}</p>

      <div className="centered">
        <span className="button cPointer" onClick={() => setOpened(false)}>{strings('common.close')}</span>
      </div>
    </Popup>
  );
}

