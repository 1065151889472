import { useEffect, useRef, useState } from 'react';
import '../../css/counter.css';

function Counter({ id, value, minValue, maxValue, onChange, style }) {
  const [originalValue, setOriginalValue] = useState();
  const [currentValue, setCurrentValue] = useState(!isNaN(value) ? parseInt(value) : 0);

  const mounted = useRef();
  useEffect(() => {
    if (value !== mounted.previousValue) {
      setCurrentValue(value);
      mounted.previousValue = value;
    }

  }, [value, setCurrentValue]);

  function checkNewValue() {
    if (isNaN(currentValue) || (minValue != null && parseInt(currentValue) < minValue) || (maxValue != null && parseInt(currentValue) > maxValue)) {
      setCurrentValue(originalValue);
    } else {
      setCurrentValue(parseInt(currentValue));
      if (parseInt(currentValue) !== originalValue) {
        onChange(parseInt(currentValue));
      }
    }
  }

  function decrementNumber() {
    if (minValue == null || parseInt(currentValue) - 1 >= minValue) {
      setCurrentValue(parseInt(currentValue) - 1);
      onChange(parseInt(currentValue) - 1);
    }
  }

  function incrementNumber() {
    if (maxValue == null || parseInt(currentValue) + 1 <= maxValue) {
      setCurrentValue(parseInt(currentValue) + 1);
      onChange(parseInt(currentValue) + 1);
    }
  }

  return (
    <div className="cartItemsCounterContainer" style={style}>
      <div className="cartItemsCounter">
        <button className={'cartItemDecr' + (minValue == null || parseInt(minValue) < parseInt(currentValue) ? '' : ' disabled')} onClick={decrementNumber}>
          <span className="cartItemIcon">-</span>
        </button>
        <div className="cartItemInput">
          <input type="number" name={id} disabled lang="fr" value={currentValue} onChange={newValue => setCurrentValue(newValue)}
            onFocus={() => setOriginalValue(parseInt(value))} onBlur={checkNewValue} />
        </div>
        <button className={'cartItemCrec' + (maxValue == null || parseInt(maxValue) > parseInt(currentValue) ? '' : ' disabled')} onClick={incrementNumber}>
          <span className="cartItemIcon">+</span>
        </button>
      </div>
    </div>
  );
}

export default Counter;
