import { Route, Routes } from 'react-router-dom';
import { ParamsProvider } from './js/ParamsContext';
import { LanguageRedirect } from './components/Language';
import Layout from './views/Layout';
import AuthLayout from './components/auth/AuthLayout';
import VisitorLayout from './components/auth/VisitorLayout';
import ScannerLayout from './components/auth/ScannerLayout';
import DeveloperLayout from './components/auth/DeveloperLayout';
import Home from './views/public/Home';
import Billeterie from './views/public/Billeterie';
import Payment from './views/public/Payment';
import PaymentResponse from './views/public/PaymentResponse';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import Dashboard from './views/logged/Dashboard';
import Visited from './views/logged/Visited';
import Logout from './views/Logout';
import Competition from './views/public/Competition';
import Gallery from './views/public/Gallery';
import GalleryVideo from './views/public/GalleryVideo';
import Exposant from './views/public/Exposant';
import Workshops from './views/public/Workshops';
import Workshop from './views/public/Workshop';
import Emails from './views/logged/Emails';
import Entrances from './views/logged/Entrances';
import Exposants from './views/logged/Exposants';
import './index.css';
import './css/icofont.css';

export default function App() {
  return (
    <ParamsProvider>
      <LanguageRedirect />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/billeterie" element={<Billeterie />} />
          <Route path="/workshops" element={<Workshops />} />
          <Route path="/workshop/:workshopName" element={<Workshop />} state={{ showMenuBar: true }} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-response" element={<PaymentResponse />} />
          <Route path="/competition" element={<Competition />} />
          <Route path="/exposant" element={<Exposant />} />
          <Route path="/images" element={<Gallery />} state={{ showMenuBar: true }} />
          <Route path="/videos" element={<GalleryVideo />} />
          <Route element={<AuthLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/visited" element={<Visited />} />
          </Route>
          <Route element={<VisitorLayout />}>
          </Route>
          <Route element={<ScannerLayout />}>
          </Route>
          <Route element={<DeveloperLayout />}>
            <Route path="/emails" element={<Emails />} />
            <Route path="/exposants" element={<Exposants />} />
            <Route path="/entrances" element={<Entrances />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<AuthLayout />}>
          <Route path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </ParamsProvider>
  );
}