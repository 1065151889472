import { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GET_WORKSHOPS, getEventId } from '../../js/endpoints';
import { request } from '../../js/APIUtils';
import { strings } from '../../components/Language';
import Loading from '../../components/Loading';
import { formatDate } from '../../js/Common';

export default function Workshops() {
  const [workshops, setWorkshops] = useState([]);
  const [loading, setLoading] = useState(true);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      getEventId().then(eventId => {
        request(GET_WORKSHOPS, { eventId: eventId }).then(async (jsonObject) => {
          setWorkshops(jsonObject.workshopList);
          setLoading(false);
        });
      });

      mounted.current = true;
    }
  }, [setWorkshops]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/workshops.css" />
        </Helmet>
        <Loading isLoading={loading} />

        <div id="workshops" className="row section workshopsSection">
          <div className="workshopsOverlay" />
          <div className="sectionContainer workshopsContainer">
            <div className="workshopsTitleContainer">
              <h2 className="workshopsTitle">
                {strings('menu.workshops')}
              </h2>

              <p className="workshopsSvg">
                <svg width="71px" height="11px">
                  <path fillRule="evenodd"
                    d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                  </path>
                </svg>
              </p>

            </div>

            <div className="workshopsInfoWrapContainer">
              {workshops.map((workshop, index) =>
                <div className='workshopsInfoWrap' key={index}>
                  <div className='workshopsInfoContainer'>
                    <div className="centered">
                      <h1 className='blue fSize26 margTop10'>{workshop.name}</h1>
                      <div className='workshopDescription' dangerouslySetInnerHTML={{ __html: workshop.description }} />
                      <div className="workshopsPerson">
                        <div className="imgContainer">
                          <img alt={workshop.teacherName} src={workshop.teacherPhoto.replaceAll('{httpdHostname}', process.env.REACT_APP_HTTPD_HOSTNAME)} />
                        </div>
                        <p className="text bold">{workshop.teacherName}</p>
                      </div>
                      <p>📅 {formatDate(workshop.date, 'dddd D MMMM YYYY')} - {formatDate(workshop.start, 'HH:mm')} {strings('common.toPrep')} {formatDate(workshop.end, 'HH:mm')}</p>
                      <div>
                        <button className="button w100" onClick={() => window.location = 'workshop/' + workshop.url}>{strings('workshop.moreInfo')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}
