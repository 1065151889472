import { useEffect, useRef } from 'react';
import '../css/modal.css';

export default function Popup({ children, id, opened, setOpened }) {

  const mounted = useRef();
  useEffect(() => {
    if (opened) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }

    if (mounted.opened !== opened && id != null) {
      const scrollableElements = document.getElementById(id)?.getElementsByClassName('scrollable');
      if (scrollableElements.length > 0) {
        const modal = document.getElementById(id).getElementsByClassName('modal-content')[0];
        const children = modal.children;

        let usedHeight = 0;
        for (let child of children) {
          if (window.getComputedStyle(child).position !== 'absolute' && window.getComputedStyle(child).position !== 'fixed' && !child.classList.contains('scrollable')) {
            const computedStyle = window.getComputedStyle(child);
            usedHeight += child.offsetHeight + parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom)
              + parseFloat(computedStyle.borderTopWidth) + parseFloat(computedStyle.borderBottomWidth)
          }
        }

        const computedStyle = window.getComputedStyle(modal);
        const popupSize = window.innerHeight - (parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom)
          + parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
          + parseFloat(computedStyle.borderTopWidth) + parseFloat(computedStyle.borderBottomWidth));
        const availableHeight = (popupSize - usedHeight) / scrollableElements.length;

        for (let scrollableElement of scrollableElements) {
          scrollableElement.style.maxHeight = availableHeight + 'px';
        }
      }

      mounted.opened = opened;
    }

    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [opened, id]);

  return (
    <div id={id} className="modal" style={opened ? { display: 'block' } : { display: 'none' }} onClick={() => setOpened(false)}>
      <div className="modal-content" onClick={(event) => event.stopPropagation()}>
        <span className="closeModal" onClick={() => setOpened(false)}>&times;</span>

        {children}
      </div>
    </div>
  );
}

