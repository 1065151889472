import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils';
import { EVENT_YEARS, GET_ENTRANCE, getUrlParameter } from '../../js/endpoints';
import UserCard from '../../components/UserCard'
import Multiselect from '../../components/inputs/Multiselect'
import { strings } from '../../components/Language';
import { useParamsContext } from '../../js/ParamsContext';
import { formatDate } from '../../js/Common';

const TIME_PATTERN = 'HH:mm'

export default function Dashboard() {
  const [minHeight, setMinHeight] = useState(100);
  const [eventId, setEventId] = useState([])
  const [userId, setUserId] = useState()
  const [years, setYears] = useState([])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [users, setUsers] = useState(null)
  const [relations, setRelations] = useState([])
  const { setParams } = useParamsContext();

  const loadEntrances = useCallback(eventId => {
    setEventId(eventId);

    const body = { eventId: eventId };
    request(GET_ENTRANCE, body).then(jsonObject => {
      setUsers(jsonObject.entrances)
      setRelations(jsonObject.relations)

      calculateHeight()
    })
  }, [])

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });

      const id = getUrlParameter('id');
      if (id) {
        const body = { id: id };
        request(GET_ENTRANCE, body).then(jsonObject => {
          setUsers(jsonObject.entrances)
          setRelations(jsonObject.relations)
          setUserId(id)
          calculateHeight()
        })
      } else {
        request(EVENT_YEARS).then(jsonObject => {
          setYears(jsonObject.list)

          const defaultEvent = jsonObject.list.filter(y => y.label === new Date().getFullYear().toString());
          let eventId;
          if (defaultEvent.length === 1) {
            eventId = defaultEvent[0].value;
          } else {
            eventId = jsonObject.list[jsonObject.list.length - 1].value;
          }

          setSelectedYear(jsonObject.list.filter(y => y.value === eventId)[0].label);
          loadEntrances([eventId]);
        })
      }

      mounted.current = true;
    }

  }, [setUsers, loadEntrances, setParams]);

  const calculateHeight = () => {
    const pageContainer = document.querySelector('.pageContainer');
    const marginTop = parseInt(window.getComputedStyle(pageContainer).getPropertyValue('margin-top'));

    const footer = document.getElementsByTagName('footer');
    const height = footer[0].offsetHeight;
    setMinHeight(window.innerHeight - (marginTop + height));
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/logged/dashboard.css" />
        </Helmet>

        <div className="pageContainer" style={{ minHeight: minHeight + 'px' }}>
          <div className="main-body">
            {userId != null ?
              null
              :
              <div style={{ display: 'none' }}>
                <Multiselect id="eventId" options={years} value={eventId} className={'inputElement'} multiple={false}
                  onChange={newValue => loadEntrances(newValue)} label={strings('dashboard.event')} />
              </div>
            }

            {relations.length > 0 ?
              <div className='scannedText no-print'>
                <p className='bold centered'>{relations.length === 1 ? strings('dashboard.scannedUserSing', { times: relations.length }) : strings('dashboard.scannedUserPlural', { times: relations.length })}:</p>
                <ul>
                  {relations.map((relation, index) =>
                    <li key={index}>{relation.user.name ? relation.user.name + ' ' + relation.user.lastName : relation.user.email} {strings('common.atPrep')} {formatDate(relation.dateCreated, TIME_PATTERN)}</li>
                  )}
                </ul>
              </div>
              :
              null
            }

            {users != null ?
              users.length > 0 ?
                users.map((user, index) =>
                  <UserCard key={index} user={user} red={relations.length > 0} />
                )
                :
                <div className='card'>
                  <div className="card-body">
                    <div className="centered">
                      <h4>{strings('dashboard.noEntrances', { year: selectedYear })}</h4>
                    </div>
                  </div>
                </div>
              :
              null
            }
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}
