const EMAIL_PATTERN_COMPONENT = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
const NUMBER_PATTERN = '^[0-9]*$';
const SPACE_PATTERN = /\s/;

export function isValidEmail(email) {
    const EMAIL_ALLOWED_LENGTH = 100;

    let success = false;

    if (email.length <= EMAIL_ALLOWED_LENGTH && email.split('@').length === 2) {
        const pattern = new RegExp(EMAIL_PATTERN_COMPONENT, 'i');
        const patternSpaces = new RegExp(SPACE_PATTERN, 'i');

        success = !!pattern.test(email.toLowerCase()) && email.toLowerCase().match(EMAIL_PATTERN_COMPONENT)[0] === email.toLowerCase();
        success = success && !patternSpaces.test(email);
    }

    return success;
}

export function isValidPhone(number) {
    const pattern = new RegExp(NUMBER_PATTERN, 'i');

    return number != null && number.length > 6 && (!!pattern.test(number) || (number.startsWith('+') && !!pattern.test(number.substring(1))));
};

export function validateFields(object) {
    let allValid = true;
    for (let key of Object.keys(object)) {
        if (!key.endsWith('Error') && !key.endsWith('Field') && !key.endsWith('Required') && !key.endsWith('ObjType')
            && (object[key + 'Required'] || object[key] != null)) {
            let valid;
            const type = object[key + 'ObjType'];
            if (type === 'email') {
                valid = object[key] != null && isValidEmail(object[key]);
            } else if (type === 'phone') {
                valid = object[key] != null && isValidPhone(object[key]);
            } else if (key === 'multiselect') {
                valid = object[key] != null && object[key].length > 0;
            } else if (key === 'number') {
                valid = object[key] != null && !isNaN(object[key]);
            } else {
                valid = object[key] != null && object[key] !== '';
            }

            object[key + 'Error'] = !valid;
            allValid = allValid && valid;
        }
    }
    return allValid;
};