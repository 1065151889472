import { strings, getLocale } from "./Language";

function DisplayPrice({ price, currency, className }) {
  let formattedPrice = '';
  const currencyStr = currency != null ? strings('common.' + currency) : ''
  if (price != null && currency != null) {
    formattedPrice = new Intl.NumberFormat(getLocale()).format(price);
  }

  return (
    <span className={className}>{formattedPrice} {currencyStr}</span>
  );
}

export default DisplayPrice;
