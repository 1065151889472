import { useState } from 'react';
import '../../css/switch.css';

export default function Switch({ label, value, onChange, className }) {
  const [defaultValue, setDefaultValue] = useState(value || false);

  function changeValue() {
    onChange(!defaultValue)
    setDefaultValue(!defaultValue);
  }

  return <div className={'switchContainer ' + className}>
    <label className={'switch cPointer' + (defaultValue ? ' checked' : '')}>
      <input type='checkbox' value={defaultValue} onClick={changeValue} />
      <span className='slider round' />
    </label>
    <span className='switchText' onClick={changeValue}>{label}</span>
  </div>
}
