import moment from 'moment/min/moment-with-locales'
import { getLocale } from '../components/Language';

const debouncedResizeEnd = debounce(calculatePosition, 300);

export const ROLE = {
    Visitor: 'VISITOR',
    Scanner: 'SCANNER',
    Developer: 'DEVELOPER'
}

export const DEFAULT_VIEW = {
    Visitor: '/dashboard',
    Scanner: '/dashboard',
    Developer: '/dashboard'
}

export function formatDate(date, format) {
    moment.locale(getLocale());
    let momentDate;
    if (date != null && date.hour != null) {
        const timeString = `${date.hour}:${date.minute}:${date.second}`;
        momentDate = moment(timeString, 'HH:mm:ss');
    } else {
        const dateObj = new Date(date);
        momentDate = moment(dateObj);
    }
    return momentDate.format(format);
}

export function addParameterToUrl(param, value) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(param, value);

    const newUrl = decodeURIComponent(searchParams.toString());
    window.history.replaceState(window.location.search, null, newUrl !== '' ? '?' + newUrl : '');
};

export function setTooltipsPosition() {
    window.removeEventListener('resize', debouncedResizeEnd);
    window.addEventListener('resize', debouncedResizeEnd);
    calculatePosition();
}

function debounce(func, wait) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

function calculatePosition() {
    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach(tooltip => {
        const tooltipText = tooltip.querySelector('.tooltiptext');
        if (tooltip.closest('.tableScroll') || tooltip.closest('.modal-content')) {
            const parent = tooltip.closest('.tableScroll') || tooltip.closest('.modal-content');
            const tooltipRect = tooltipText.getBoundingClientRect();
            const parentRect = parent.getBoundingClientRect();
            const totalWidth = parent.querySelector('table') ? parent.querySelector('table').offsetWidth : parent.offsetWidth;
            const current = -(tooltipRect.width / 2) - 5;
            const rightPosition = parentRect.left + totalWidth;

            if (tooltipRect.left < parentRect.left) {
                tooltipText.style.transform = 'translate(' + (tooltipRect.left - parentRect.left) + 'px, -100%)';
            } else if (tooltipRect.right > rightPosition) {
                tooltipText.style.transform = 'translate(' + (current + (rightPosition - tooltipRect.right)) + 'px, -100%)';
            } else {
                tooltipText.style.transform = 'translate(-50%, -100%)';
            }
        }
    });
}