import { useEffect, useState } from 'react';

export default function Loading({ isLoading }) {
  const [height, setHeight] = useState(0)
  const [top, setTop] = useState(0)

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;

    setHeight(Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight));
    setTop(html.scrollTop || body.scrollTop);
  }, [isLoading, setHeight, setTop]);

  return (isLoading ?
    <div className="pageLoadingLayout" style={{ height: height, paddingTop: top }}>
      <img width="230" height="46" className="loading" src={require('../loading.gif')} alt="Loading" />
    </div>
    :
    null
  );
}
