import React, { useEffect, useRef, useState } from 'react';
import { NEXT_EVENT, SAVE_EXPOSANT } from '../../js/endpoints.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { strings } from '../../components/Language.js';
import { request } from '../../js/APIUtils.js';
import { isValidEmail, isValidPhone } from '../../js/validator.js';

const EMPTY_EXPOSANT = {
  name: '',
  nameError: false,
  email: '',
  emailError: false,
  phone: '',
  phoneError: false,
  business: '',
  businessError: false,
}

export default function Exposant() {
  const [eventId, setEventId] = useState(null)
  const [exposant, setExposant] = useState({ ...EMPTY_EXPOSANT });
  const [containerHeight, setContainerHeight] = useState(500)

  const [submitting, setSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [saved, setSaved] = useState(null);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      request(NEXT_EVENT).then(jsonObject => {
        if (jsonObject.httpStatus === 200) {
          setEventId(jsonObject.event.id);
        }
      })
      mounted.current = true;
    } else if (hasError) {
      setHasError(false);
    }

    const height = window.innerHeight;
    const footer = document.getElementsByTagName('footer')[0];

    if (footer.offsetHeight === 0) {
      setTimeout(() => setContainerHeight(height - footer.offsetHeight), 500);
    }
  }, [hasError, setHasError]);

  function updateExposant(target) {
    exposant[target.id] = target.value;
    setExposant(exposant);
  }

  function checkExposant() {
    let allValid = true;
    for (let key of Object.keys(exposant)) {
      if (!key.endsWith('Error') && !key.endsWith('Field')) {
        let valid;
        if (key === 'email') {
          valid = exposant[key] != null && isValidEmail(exposant[key]);
        } else if (key === 'phone') {
          valid = exposant[key] != null && isValidPhone(exposant[key]);
        } else {
          valid = exposant[key] != null && exposant[key] !== '';
        }

        exposant[key + 'Error'] = !valid;
        allValid = allValid && valid;
      }
    }

    if (allValid) {
      setSubmitting(true);
      const body = {
        eventId: eventId,
        ...exposant
      }

      request(SAVE_EXPOSANT, body).then(jsonObject => {
        setSaved(jsonObject.httpStatus === 200);
      });
    } else {
      setExposant(exposant);
      setHasError(true);
    }
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/exposant.css" />
        </Helmet>

        <div className="row section exposantSection" style={{ minHeight: containerHeight + 'px' }}>
          <div className="exposantOverlay" />
          <div className="sectionContainer exposantContainer">
            <div className="exposantTitleContainer">
              <h2 className="exposantTitle">
                {strings('exposant.title')}
              </h2>
              <p className="subtitle" dangerouslySetInnerHTML={{ __html: strings('exposant.subtitle') }} />

              <div className="exposantInfoWrapContainer">
                <div className="exposantInfoWrap">
                  {saved != null ?
                    saved ?
                      <div id="dataSuccess" className="alert alert-success">
                        <i className="icon-checked" />
                        {strings('exposant.sentOK')}
                      </div>
                      :
                      <div id="dataError" className="alert alert-danger">
                        <i className="icon-cross" />
                        {strings('exposant.sentKO')}
                      </div>
                    :
                    <div className="exposantInfoContainer">
                      <div>
                        <p className="label">{strings('billeterie.lastName')} *</p>
                        <input type="text" id="name" className={'inputElement' + (exposant.nameError ? ' error' : '')} maxLength="150" defaultValue={exposant.name}
                          onChange={event => updateExposant(event.target)} />
                        <p className="label">{strings('common.email')} *</p>
                        <input type="email" id="email" className={'inputElement' + (exposant.emailError ? ' error' : '')} maxLength="150" defaultValue={exposant.email}
                          onChange={event => updateExposant(event.target)} />
                        <p className="label">{strings('common.phone')} *</p>
                        <input type="tel" id="phone" className={'inputElement' + (exposant.phoneError ? ' error' : '')} maxLength="150" defaultValue={exposant.phone}
                          onChange={event => updateExposant(event.target)} />
                        <p className="label">{strings('billeterie.business')} *</p>
                        <input type="text" id="business" className={'inputElement' + (exposant.businessError ? ' error' : '')} maxLength="150" defaultValue={exposant.business}
                          onChange={event => updateExposant(event.target)} />

                        <button onClick={() => checkExposant()} className={'exposantBtn' + (submitting ? ' disabled' : '')}>{strings('billeterie.subscribe')}</button>
                      </div>
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}
