import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from '../../js/APIUtils';
import { DEFAULT_VIEW, ROLE } from '../../js/Common';

const REDIRECTED_URL_KEY = 'redirectedUrl';

const ScannerLayout = () => {
  const [userAuthenticated, setUserAuthenticated] = useState(null);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      isAuthenticated().then(response => {
        setUserAuthenticated(response);
      });

      mounted.current = true;
    }
  }, []);

  if (userAuthenticated === null) {
    // Aún se está esperando la respuesta de autenticación, puedes mostrar una pantalla de carga o algo similar
    return <div className="loadingGif"><img width="230" height="46" src={require('../../loading.gif')} alt="Loading" /></div>;
  }

  if (userAuthenticated?.role === ROLE.Scanner) {
    const redirectedUrl = localStorage.getItem(REDIRECTED_URL_KEY);
    if (redirectedUrl != null) {
      window.location.href = redirectedUrl;
      localStorage.removeItem(REDIRECTED_URL_KEY);
    }
    return <Outlet />;
  } else if (userAuthenticated?.id != null) {
    localStorage.removeItem(REDIRECTED_URL_KEY);
    return <Navigate to={DEFAULT_VIEW.Scanner} replace />;
  }

  localStorage.setItem(REDIRECTED_URL_KEY, window.location.href);
  return <Navigate to='/login' replace />;
};

export default ScannerLayout;