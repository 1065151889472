import React, { useEffect, useImperativeHandle, useRef } from 'react';
import AceEditor from 'react-ace';

// Autocompletion
import 'ace-builds/src-noconflict/ext-language_tools';

// Modes
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/snippets/css';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-html';

// Themes
import 'ace-builds/src-noconflict/theme-tomorrow';

// Beautify
import 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/ext-searchbox';

const TAB_SIZE = 4;
const CodeEditor = React.forwardRef(({ value, onChange, mode, height }, ref) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && window.ace) {
      beautify()
    }
  }, [ref]);

  const beautify = () => {
    const ace = window.ace;
    const editor = editorRef.current.editor;
    const beautify = ace.require('ace/ext/beautify');
    editor.session.setOptions({ tabSize: TAB_SIZE, useSoftTabs: true });
    editor.session.setMode('ace/mode/html');
    editor.commands.addCommand({
      name: 'beautify',

      exec: () => beautify.beautify(editor.session)
    }, {
      name: 'find',
      bindKey: { win: 'Ctrl-F', mac: 'Command-F' },
      exec: () => editor.execCommand("find")
    });
  }

  useImperativeHandle(ref, () => ({
    beautify
  }));

  return <AceEditor
    ref={editorRef}
    mode={mode}
    theme='tomorrow'
    name='code-editor'
    onChange={onChange}
    fontSize={13}
    width='100%'
    height={height + 'px'}
    showPrintMargin={true}
    showGutter={true}
    highlightActiveLine={true}
    value={value}
    setOptions={{
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: TAB_SIZE,
      useWorker: false
    }}
  />
});

export default CodeEditor;