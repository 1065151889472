import { useNavigate } from 'react-router-dom';
import { USER_KEY, request } from '../js/APIUtils';
import { LOGOUT } from '../js/endpoints';
import { useCallback, useEffect, useRef } from 'react';

export default function Logout() {
  const navigate = useNavigate();

  const goHome = useCallback(() => {
    navigate('/')
  }, [navigate]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      request(LOGOUT).then(() => {
        localStorage.removeItem(USER_KEY);
        goHome();
      })

      mounted.current = true;
    }
  }, [goHome]);

  return (
    <div className="loadingGif"><img width="230" height="46" src={require('../loading.gif')} alt="Loading" /></div>
  );
}