import React from 'react';
import Popup from './Popup';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import '../css/pdf-viewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@' + pdfjs.version + '/build/pdf.worker.min.mjs';

const PdfPreview = ({ pdfUrl, opened, setOpened }) => {
  return (
    <Popup id='pdfPopup' opened={opened} setOpened={setOpened}>
      <div className='scrollable'>
        <Document file={pdfUrl}>
          <Page pageNumber={1} />
        </Document>
      </div>
    </Popup>
  );
};

export default PdfPreview;