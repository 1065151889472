import React, { useEffect, useRef, useState } from 'react';
import { NEXT_EVENT } from '../../js/endpoints.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { strings } from '../../components/Language.js';
import { request } from '../../js/APIUtils.js';
import { formatDate } from '../../js/Common.js';

const FORM_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSfYgl2EeSBKf8kAyjsGWXU8MmBY9UVlXsXGvGK2upjkSHNB0A/viewform?usp=sf_link';

export default function Competition() {
  const [year, setYear] = useState(new Date().getFullYear())
  const [competitionDay, setCompetitionDay] = useState(null)

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      request(NEXT_EVENT).then(jsonObject => {
        if (jsonObject.httpStatus === 200) {
          const dates = jsonObject.event.days.map(day => new Date(day.date));
          const competitionEventDay = new Date(Math.min(...dates));
          setCompetitionDay(competitionEventDay);
          setYear(competitionEventDay.getFullYear())
        }
      })
      mounted.current = true;
    }
  }, []);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/competition.css" />
        </Helmet>

        <div className="row section competitionSection">
          <div className="competitionOverlay" />
          <div className="sectionContainer competitionContainer">
            <div className="competitionTitleContainer">
              <h2 className="competitionTitle">
                {strings('competition.title')}
              </h2>
              <p className="subtitle">{strings('competition.subtitle', { year: year, competitionDay: formatDate(competitionDay, 'dddd D MMMM YYYY') })}
              </p>

              <div className='centered competitionBtnContainer'>
                <a href={FORM_LINK} target='_blank' rel='noreferrer' className='competitionBtn'>{strings('competition.signup')}</a>
              </div>
            </div>
          </div>
        </div>

        <div id="info" className="row section">
          <div className="sectionContainer">
            <div className="sectionRow">
              <div className="col-lg-4">
                <div className="competitionInfoBox">
                  <h3 className="competitionInfoBoxTitle"><i className="icon icon-tscalender2" /> {strings('competition.infoTitle1')}</h3>
                  <div className="competitionInfoBoxText">
                    <ul className='competitionInfoBoxList'>
                      <li dangerouslySetInnerHTML={{ __html: strings('competition.infoText11') }} />
                      <li dangerouslySetInnerHTML={{ __html: strings('competition.infoText12') }} />
                      <li dangerouslySetInnerHTML={{ __html: strings('competition.infoText13', { year: year, competitionDay: formatDate(competitionDay, 'dddd D MMMM YYYY') }) }} />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="competitionInfoBox">
                  <h3 className="competitionInfoBoxTitle"><i className="icon icon-people" /> {strings('competition.infoTitle2')}</h3>
                  <div className="competitionInfoBoxText">
                    <ul className='competitionInfoBoxList'>
                      <li dangerouslySetInnerHTML={{ __html: strings('competition.infoText21', { year: year }) }} />
                      <li dangerouslySetInnerHTML={{ __html: strings('competition.infoText22') }} />
                      <li dangerouslySetInnerHTML={{ __html: strings('competition.infoText23') }} />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="competitionInfoBox">
                  <h3 className="competitionInfoBoxTitle"><i className="icon icon-justice" /> {strings('competition.infoTitle3')}</h3>
                  <p className="competitionInfoBoxText">
                    {strings('competition.infoText3')}
                  </p>
                </div>
              </div>
            </div>

            <h2 className="competitionProcedureTitle centered">
              {strings('competition.procedureTitle')}
            </h2>
            <p className="competitionProcedureText centered">
              {strings('competition.procedureText')}
            </p>

            <div className="sectionRow">
              <div className="col-lg-4">
                <div className="competitionProcedureBox">
                  <h3 className="competitionProcedureBoxNumber">
                    <span><i className="icon icon-agenda" /> 01</span>
                  </h3>
                  <h3 className="competitionProcedureBoxTitle">{strings('competition.procedureTitle1')}</h3>
                  <p className="competitionInfoBoxText" dangerouslySetInnerHTML={{ __html: strings('competition.procedureDesc1') }} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="competitionProcedureBox">
                  <h3 className="competitionProcedureBoxNumber">
                    <span><i className="icon icon-people" /> 02</span>
                  </h3>
                  <h3 className="competitionProcedureBoxTitle">{strings('competition.procedureTitle2')}</h3>
                  <p className="competitionInfoBoxText" dangerouslySetInnerHTML={{ __html: strings('competition.procedureDesc2') }} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="competitionProcedureBox">
                  <h3 className="competitionProcedureBoxNumber">
                    <span><i className="icon icon-medal" /> 03</span>
                  </h3>
                  <h3 className="competitionProcedureBoxTitle">{strings('competition.procedureTitle3')}</h3>
                  <p className="competitionInfoBoxText" dangerouslySetInnerHTML={{ __html: strings('competition.procedureDesc3', { year: year, competitionDay: formatDate(competitionDay, 'dddd D MMMM') }) }} />
                </div>
              </div>
            </div>

            <h2 className="competitionTropheeTitle centered">{strings('competition.trophee', { year: year })}</h2>
            <p className="competitionTropheeText centered">{strings('competition.tropheeDesc', { competitionDay: formatDate(competitionDay, 'dddd D MMMM YYYY') })}</p>
            <div className='centered'>
              <a href={FORM_LINK} target='_blank' rel='noreferrer' className="competitionTropheeLink">
                <span>{strings('competition.signup')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider >
  );
}
